<template>
  <div class="container my-24 my-lg-40">
    <div class="row">
      <div class="col-12">
        <v-sheet
          rounded
          elevation="4"
          class="py-32 px-16 p-xl-32"
        >
          <div class="row">
            <div class="col">
              <p>Каковы ваши впечатления от участия в программе? Оцените по 10-бальной шкале (1 - не соответствует
                ожиданиям, 10 - превосходит ожидания).</p>
            </div>
          </div>

          <div class="row">
            <div
              v-for="item of variant"
              :key="item.id"
              class="col-auto mt-16 d-none d-md-block"
            >
              <v-button
                :active="answer === item.id"
                icon
                outlined
                round
                @click="answer = item.id"
              >
                {{ item.text }}
              </v-button>
            </div>

            <div class="col mt-16 d-md-none">
              <div class="row">
                <div class="col px-24">
                  <v-slider
                    v-model.number.trim="answer"
                    :variants="variant"
                    :lineHeight="24"
                  />
                </div>
              </div>

              <div class="row justify-content-between text-size-12 text-center">
                <div class="col-auto">
                  <div class="w-32px">1</div>
                </div>
                <div class="col-auto">
                  <div class="w-32px">10</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-40">
            <div class="col">
              <v-button>
                Ответить
              </v-button>
            </div>
          </div>
        </v-sheet>
      </div>
    </div>
  </div>
</template>

<script>
import { VButton, VSlider } from '@components/base'
import VSheet from '@components/base/VSheet/VSheet'

export default {
  name: 'SNlmkGraduatedScale',

  components: {
    VSheet,
    VButton,
    VSlider
  },

  data () {
    return {
      answer: 0,
      variant: [
        {
          attribute: '',
          group: '',
          id: '0',
          media: '',
          sort: '',
          label: 'Описание 0',
          text: '0'
        },
        {
          attribute: '',
          group: '',
          id: '1',
          media: '',
          sort: '',
          label: 'Описание 1',
          text: '1'
        },
        {
          attribute: '',
          group: '',
          id: '2',
          media: '',
          sort: '',
          label: 'Описание 2',
          text: '2'
        },
        {
          attribute: '',
          group: '',
          id: '3',
          media: '',
          sort: '',
          label: 'Описание 3',
          text: '3'
        },
        {
          attribute: '',
          group: '',
          id: '4',
          media: '',
          sort: '',
          label: 'Описание 4',
          text: '4'
        },
        {
          attribute: '',
          group: '',
          id: '5',
          media: '',
          sort: '',
          label: 'Описание 5',
          text: '5'
        },
        {
          attribute: '',
          group: '',
          id: '6',
          media: '',
          sort: '',
          label: 'Описание 6',
          text: '6'
        },
        {
          attribute: '',
          group: '',
          id: '7',
          media: '',
          sort: '',
          label: 'Описание 7',
          text: '7'
        },
        {
          attribute: '',
          group: '',
          id: '8',
          media: '',
          sort: '',
          label: 'Описание 8',
          text: '8'
        },
        {
          attribute: '',
          group: '',
          id: '9',
          media: '',
          sort: '',
          label: 'Описание 9',
          text: '9'
        },
        {
          attribute: '',
          group: '',
          id: '10',
          media: '',
          sort: '',
          label: 'Описание 10',
          text: '10'
        }
      ]
    }
  },

  methods: {
    onClick () {
      console.log('Ответ')
    }
  }
}
</script>
